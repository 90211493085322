.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px;
  z-index: 100;
}

.links {
  display: flex;
  gap: 10px;
  list-style: none;
}

.links a {
  text-decoration: none;
  color: #ff647f;
  transition: color 0.3s ease;
}

.links a:hover {
  color: #7a2331;
}

.title img {
  width: 100px; /* your current setting */
  height: 60px; /* adjust based on your preference */
  object-fit: cover; /* or 'contain' to avoid cropping but might introduce letterboxing */
}
