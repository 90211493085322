.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f0f0f0; /* Adjust the background color as needed */
}

.nav {
  flex-grow: 1;
  padding: 1rem;
}

.navList {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navItem {
  margin-right: 20px; /* Space between links */
}

.navLink {
  text-decoration: none;
  color: #333; /* Link color */
}

.rsvpButton {
  background-color: #ff4061; /* Button color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.rsvpButton:hover {
  background-color: #4a3328; /* Darker shade on hover */
}

/* Copyright styling */
copyright {
  margin-left: 20px; /* Space from the button */
  color: #666; /* Adjust color as needed */
}
