.about {
  background: pink;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.heading {
  font-size: 2em;
  color: #5c4033;
  text-align: center;
  padding: 2rem 0 1rem 0;
}

p {
  padding: 0rem 4rem;
  font-size: 1em;
  color: #333333;
  line-height: 1.5;
  margin-bottom: 1em;
  text-align: justify;
}

a {
  color: #5c4033;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1em;
}

.image {
  max-width: 30%;
  margin-right: 2em;
  border-radius: 5px;
}

.textContent {
  max-width: 70%;
}

@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
    align-items: center;
  }

  .image {
    max-width: 80%;
    margin-bottom: 1em;
  }

  .textContent {
    max-width: 100%;
  }
}

.rsvpButton {
  background: #ff4061;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 2rem auto 3rem auto;
}
