.menu {
  font-family: "Open Sans", sans-serif;
  background-color: #f0eae4; /* Soft background color */
  padding: 40px 20px;
  border-radius: 8px; /* Optional: adds rounded corners for the background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow for depth */
}

.menuTitle {
  text-align: center;
  color: #5c4033;
  margin-bottom: 30px;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  justify-content: center;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.card h3 {
  color: #5c4033;
}

.card p {
  font-size: 0.95rem;
  line-height: 1.5;
}

.note {
  text-align: center;
  margin-top: 30px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cardsContainer {
    grid-template-columns: 1fr;
  }
}
