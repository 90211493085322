.directions {
  font-family: "Open Sans", sans-serif;
  background-color: #ffc0cb;
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  width: 100%;
  max-width: 1200px;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image {
  max-width: 40%;
  margin-right: 20px;
  border-radius: 5px;
}

.info {
  max-width: 60%;
  color: #333;
}

.buttons {
  text-align: center; /* Center-align the button container */
  margin-top: 20px; /* Add some space above the button */
}

.button {
  background-color: #ff4061; /* Button color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
}

.button:hover {
  background-color: #4a3328; /* Slightly darker on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .image {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .info {
    max-width: 100%;
  }
}
