/* global.css */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto+Slab:wght@400;700&display=swap");

:root {
  --pink: #ff647f;
  --white: #ffffff;
  --black: #000000;
  --grey: #f4f4f4;
  --dark-grey: #333;
  --gold: #b5a37e;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: var(--white);
  color: var(--dark-grey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", serif;
  margin-bottom: 0.5em;
}

p {
  margin-bottom: 1em;
}

a {
  color: var(--pink);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.button,
button {
  background-color: var(--pink);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  transition: background-color 0.3s ease;
}

.button:hover,
button:hover {
  background-color: var(--gold);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.section {
  margin-bottom: 2em;
  padding: 2em 0;
}

/* Add more global styles or component-specific styles as needed */
