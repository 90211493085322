.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
}

.backgroundImage {
  height: 100vh;
  background-position: center;
  animation: kenBurnsEffect 8s ease-in-out forwards;
  width: 100%;
}

@keyframes kenBurnsEffect {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1.2);
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  background-color: rgba(255, 192, 203, 0.9);
  color: #5c4033;
  border-radius: 500px;
  padding: 3rem;
  text-align: center;
  z-index: 2; /* Ensure content stays on top */
}

@media (max-width: 900px) {
  .content {
    width: 75%;
  }
}

.title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.info {
  font-size: 1.2rem;
  font-weight: 800;
  text-align: center;
}

.infoDirections {
  font-size: 1rem;
  text-align: center;
}

.rsvpButton {
  background: #ff4061;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  margin: 1rem auto 0 auto;
}

@media (max-width: 900px) {
  .infoDirections,
  .info {
    display: none;
  }
}
